import Vue from 'vue'
import VueI18n from "vue-i18n"
import { getLang } from '@/utils/storage'

// element-ui
// elment-ui：https://element.eleme.io/#/zh-CN/component/i18n
import eleLocale from 'element-ui/lib/locale'
import eleCn from 'element-ui/lib/locale/lang/zh-CN'
import eleTw from 'element-ui/lib/locale/lang/zh-TW'
import eleEn from 'element-ui/lib/locale/lang/en'
import eleId from 'element-ui/lib/locale/lang/id'
import eleTh from 'element-ui/lib/locale/lang/th'
import eleVi from './libs/element-ui/vi'    // 部分翻译不认可，使用自定义翻译
import eleJa from './libs/element-ui/ja'    // 部分翻译不认可，使用自定义翻译

// vant
// vant：https://vant-ui.github.io/vant/v2/#/zh-CN/locale
import { Locale } from 'vant'
import vanCn from 'vant/es/locale/lang/zh-CN'
import vanTw from 'vant/es/locale/lang/zh-TW'
import vanEn from 'vant/es/locale/lang/en-US'
import vanTh from 'vant/es/locale/lang/th-TH'
import vanVi from 'vant/es/locale/lang/vi-VN'
import vanId from './libs/vant/id-ID'       // UI库没有该语言，需要自定义翻译
import vanJa from './libs/vant/ja-JP'       // 部分翻译不认可，使用自定义翻译


// local
import sys from './sys'
import games from './games'
import cashier from './cashier'

Vue.use(VueI18n)

export const changeLang = (code) => {
    switch(code) {
        case 'zh-CN':
            eleLocale.use(eleCn)
            Locale.use('zh-CN', vanCn)
            break;
        case 'zh-TW':
            eleLocale.use(eleTw)
            Locale.use('zh-TW', vanTw)
            break;
        case 'en-US':    
            eleLocale.use(eleEn)
            Locale.use('en', vanEn)
            break;
        case 'id-ID':  // 印尼语
            eleLocale.use(eleId)
            Locale.use('id', vanId)
            break;
        case 'th-TH':  // 泰语
            eleLocale.use(eleTh)
            Locale.use('th', vanTh)
            break;
        case 'vi-VN':  // 越南语
            eleLocale.use(eleVi)
            Locale.use('vi', vanVi)
            break;
        case 'ja-JP':  // 日语
            eleLocale.use(eleJa)   
            Locale.use('vi', vanJa)
            break;
    }
}

export default new VueI18n ({
    locale: getLang() || 'zh-CN',
    messages: {
        'zh-CN': {
            sys: sys.cn,
            ...games.cn,
            cashier: cashier.cn
        },
        'zh-TW': {
            sys: sys.tw,
            ...games.tw
        },
        'en-US': {
            sys: sys.en,
            ...games.en,
            cashier: cashier.en
        },
        'id-ID': {
            sys: sys.id,
            ...games.id,
            cashier: cashier.id
        },
        'th-TH': {
            sys: sys.th,
            ...games.th,
            cashier: cashier.th
        },
        'vi-VN': {
            sys: sys.vi,
            ...games.vi,
            cashier: cashier.vi
        },
        'ja-JP': {
            sys: sys.ja,
            ...games.ja
        }
    }
})


/**
 * 国际化拆分考虑点
 * 1、按游戏
 *      （1）不同游戏翻译语言不同
 *      （2）游戏二期、三期...迭代，通过js整合
 * 2、sys通用
 *      （1）不同游戏都要使用，每次都翻译
**/