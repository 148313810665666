export default {
    "header":{
        "gameRecharge":"游戏充值",
        "hello":"您好",
        "title":"充值平台",
        "saveDesktopTitle":"Journey Renewed 支付中心"
    },
    "btn":{
        "login":"登入",
        "confirm":"确定",
        "cancel":"取消",
        "clear":"清空",
        "pay":"结算",
        "payNow":"立即支付",
        "gotIt":"知道了",
        "saveDesktop":"保存桌面",
        "saveDesktopMobile":"保存桌面"
    },
    "form":{
        "label":{
            "region":"选择地区",
            "lang":"选择语言"
        },
        "placeholder":{
            "select":"请选择{name}"
        }
    },
    "warn":{
        "networkError":"网络错误，检查网络",
        "timeoutError":"请求超时，请稍后使用",
        "serviceError":"服务端异常，请稍后再试",
        "requestError":"请求异常",
        "authFail":"登录超时",
        "authVerifyFail":"登录信息校验失败",
        "loginError":"登录异常",
        "loginErrorVerify":"登入失败，请您先进入游戏激活",
        "tips":"提示",
        "noData":"暂无数据",
        "paySuccessMsg":"交易付款成功，正在跳转至商户页面",
        "payLoadingMsg":"订单结果查询中...",
        "copySuccess":"复制成功",
        "serverEmpty":"请选择服务器",
        "roleEmpty":"请选择角色",
        "loading":"加载中...",
        "noMore":"没有更多了",
        "loginTips":"请登录需要充值的游戏账号",
        "recordTips":"仅显示6个月内的储值记录",
        "pleaseSelectPayMethod":"请选择支付渠道",
        "payAmountExceedLimit":"无可用支付方式：单笔支付总金额超限，请减少购买数量或选择其他档位",
        "payWaiting":"订单支付中，请稍后......",
        "orderCancel":"订单取消后将不再进行发货，享受的优惠折扣会进行返还，请确认您没有付款并取消该笔订单？",
        "paying":"若已完成支付，请稍后在游戏内查收商品",
        "noFindRole":"未找到角色？试试切换账号>",
        "beIntercepted":"浏览器有拦截，请先取消拦截",
        "maintaining":"您好~技术维护中，预计 {date} 前开放，请稍后"
    },
    "account":{
        "switchAccount":"切换账号",
        "help":"帮助中心",
        "rechargeRecord":"交易记录",
        "welcome":"欢迎来到支付中心",
        "pleaseSelectRole":"请选择角色",
        "latestLogin":"最近登录",
        "changeRole":"更换角色",
        "loginAccount":"登入账号",
        "selectRole":"选择区服角色",
        "startReachrge":"开始充值",
        "server":"服务器",
        "roleID":"角色ID",
        "roleName":"角色名称",
        "country":"国家/地区",
        "empty":"空的",
        "noRoles":"您的账号{name}下暂时没有角色，请先进入游戏创建角色，或更换其他账号登录",
        "roleInfo":"角色信息"
    },
    "mall":{
        "title":{
            "buy":"购买商品",
            "payCart":"确认订单",
            "payType":"支付方式"
        },
        "type":{
            "card":"信用卡/储蓄卡",
            "bankApp":"手机银行",
            "bankTransfer":"银行转账",
            "onlineBank":"网上银行"
        },
        "otherCard":"其他账号",
        "totalPrize":"合计",
        "date":"日期时间",
        "dateRange":"查询日期",
        "dateBegin":"开始时间",
        "dateEnd":"结束时间",
        "rangeSeparator":"至",
        "productName":"商品名称",
        "orderAmount":"购买金额",
        "orderNumber":"订单号",
        "orderStatus":"订单状态",
        "status":{
            "unpaid":"未付款",
            "paySuccess":"支付成功",
            "payFail":"支付失败",
            "finish":"订单完成",
            "invalid":"失效"
        },
        "cancelOrder":"取消订单",
        "recommend":"最近使用",
        "buyContinue":"继续购买",
        "goodsTitle":"储值选项",
        "goodsPrizeOriginal":"商品原价",
        "goodsAdd":"商品加赠/减免",
        "buySuccess":"购买成功",
        "buySuccessMsg":"商品已发放，请在游戏内查收商品"
    },
    "pop":{
        "stepOne":"第一步：",
        "stepOneText":"点击浏览器工具",
        "stepTwo":"第二步：",
        "stepTwoText":"在弹出框中点击“添加到主屏幕”",
        "bookmarkPage":"收藏网页",
        "bookmarkPageContent":"在导航栏 <span class=\"key_words\">点击收藏图标</span> 或 <span class=\"key_words\">Ctrl+D</span> ,即可收藏网页",
        "saveDesktopAndroid":"请点击浏览器更多功能，选择“添加桌面”，或者收藏功能<span class='icon icon-star'></span>保存网页"
    }
}