import i18n from '@/i18n';
import { Message, MessageBox } from 'element-ui';
import { Dialog, Toast } from 'vant';
import { isMobile } from './utils';

export const tipsSuccess = (msg) => {
    if(isMobile()) {
        Toast.success(msg)
    } else {
        Message.success(msg)
    }
}
export const tipsError = (msg) => {
    if(isMobile()) {
        Toast(msg)
    } else {
        Message.error(msg)
    }
}
export const tipsWarning = (msg) => {
    if(isMobile()) {
        Toast(msg)
    } else {
        Message.warning(msg)
    }
}
export const tips = (message) => {
    Toast({
        message,
        duration: 4000
    })
}

export const comfirmBox = (msg, success, fail) => {
    if(isMobile()) {
        Dialog.alert({
            title: i18n.t('sys.warn.tips'),
            message: msg,
            showCancelButton: true
        }).then(() => {
            success && success()
        }).catch(() => {
            fail && fail()
        });
    } else {
        MessageBox({
            title: i18n.t('sys.warn.tips'),
            message: msg,
            showCancelButton: true,
            showConfirmButton: true
        }).then(() => {
            success && success()
        }).catch(() => {
            fail && fail()
        })
    }

}
