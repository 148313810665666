import xyCn from './xy/zh-cn'
import xyTw from './xy/zh-tw'
import xyEn from './xy/en'
import xyId from './xy/id'
import xyTh from './xy/th'
import xyVi from './xy/vi'

import psCn from './ps/zh-cn'
import psTw from './ps/zh-tw'
import psEn from './ps/en'
import psId from './ps/id'
import psTh from './ps/th'
import psVi from './ps/vi'
import psJa from './ps/ja'

export default {
    cn: {
        xy: xyCn,
        ps: psCn
    },
    tw: {
        xy: xyTw,
        ps: psTw
    },
    en: {
        xy: xyEn,
        ps: psEn
    },
    id: {
        xy: xyId,
        ps: psId
    },
    th: {
        xy: xyTh,
        ps: psTh
    },
    vi: {
        xy: xyVi,
        ps: psVi
    },
    ja: {
        ps: psJa
    }
}