export default {
    "game":{
        "name":"Gặp Lại Tây Du：Mạo Hiểm Mộng Ảo",
        "titleGat":"Nền tảng nạp công viên người chơi",
        "titleDny":"Trung Tâm Nạp Gặp Lại Tây Du"
    },
    "user":{
        "helpContent":"<p>Hướng Dẫn Trung Tâm Nạp</p><p></p><p>1. Dịch vụ này chỉ dành cho những người dùng đã liên kết với Google, Apple và Facebook.</p><p></p><p>2. Voucher mua trên trang web sẽ được cấp trực tiếp vào tài khoản trò chơi của bạn và có thể sử dụng trực tiếp trong trò chơi.Vui lòng các người chơi chú ý kiểm tra.</p><p></p><p>3. Vui lòng kiểm tra tỉ mỉ chi tiết tài khoản của bạn trước khi thanh toán. Nếu thông tin tài khoản không khớp, vui lòng thay đổi lại vai trò và sau khi chuyển sang vai trò tài khoản chính xác , tiến hành bước thanh toán tiếp theo.</p><p></p><p>4. Sau khi thanh toán thành công, nếu trong vòng 30 phút sau khi thanh toán thành công mà vẫn không nhận được giải thưởng, vui lòng liên hệ với nhân viên dịch vụ khách hàng trong trò chơi.</p><br/><p>Mô tả phiếu quà tặng:</p><p>1. Voucher là một đạo cụ trò chơi mới trong game. Voucher có thể dùng để đổi lấy các gói quà trong game.</p><p>2. Việc tham gia các hoạt động trong trò chơi sẽ căn cứ vào thời gian sử dụng thực tế của voucher. Ví dụ: Trong hoạt động nạp tích lũy, việc sử dụng voucher để đổi gói quà trong trò chơi sẽ được tính vào hoạt động nạp tích lũy; Nếu bạn chỉ mua voucher tại trung tâm nạp và không sử dụng voucher để đổi gói quà trong game sẽ không được tính vào hoạt động nạp tiền tích lũy.</p>"
    },
    "recharge":{
        "instructions":"<p>[Hướng Dẫn Nạp]</p><p>1. Vui lòng kiểm tra lại thông tin server và nhân vật trước khi nạp tiền. Sau khi nạp tiền thành công, vật phẩm sẽ được gửi trực tiếp vào hành lý của các vị Thư Linh đại nhân. <br/> Xin vui lòng chú ý kiểm tra.</p><p>2. Nạp tiền trên trang web có thể bị chậm trễ. Nếu không nhận được tiền, vui lòng thử đăng nhập lại hoặc liên hệ với bộ phận hỗ trợ khách hàng để xử lý.</p><p>3. Trung Tâm Nạp<br/> trên trang web chính thức sẽ tổ chức các sự kiện không định kỳ. Người chơi có thể theo dõi thông báo sự kiện liên quan.</p><p></p><p>[Liên hệ bộ phận hỗ trợ khách hàng]</p><p>Email hỗ trợ: CS-JRFF@cyou-inc.com</p>"
    },
    "footer":{
        "termsOfService":"Điều khoản dịch vụ",
        "privacyPolicy":"Thỏa thuận Riêng tư",
        "officialFanGroup":"Fanpage chính thức",
        "serviceCenter":"Trung tâm dịch vụ",
        "copyright":"Copyright © 2024-2025 Changyou.com HK Limited. All Rights Reserved.",
        "emailTips":"Hoan nghênh bạn gửi phản hồi của mình đến email chính thức của chúng tôi",
        "emailTipsButton":"Nhấn để sao chép địa chỉ email",
        "link_privacy":"https://jrff.gaming.com/game/vi/privacy.html",
        "link_protocol":"https://jrff.gaming.com/game/vi/protocol.html",
        "link_officialFanGroup":"https://www.facebook.com/JourneyRenewed.VI",
        "email_service":"CS-JRFF@cyou-inc.com"
    }
}