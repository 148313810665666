export default {
    "header":{
        "gameRecharge":"Nạp game",
        "hello":"Xin chào",
        "title":"Nền tảng nạp",
        "saveDesktopTitle":"Trung tâm thanh toán Gặp Lại Tây Du"
    },
    "btn":{
        "login":"Đăng nhập",
        "confirm":"Xác nhận",
        "cancel":"Hủy",
        "clear":"Xóa",
        "pay":"Thanh toán",
        "payNow":"Thanh toán ngay",
        "gotIt":"Đã hiểu",
        "saveDesktop":"Lưu màn hình chính",
        "saveDesktopMobile":"Lưu màn hình chính"
    },
    "form":{
        "label":{
            "region":"Chọn khu vực",
            "lang":"Chọn ngôn ngữ"
        },
        "placeholder":{
            "select":"Vui lòng chọn{name}"
        }
    },
    "warn":{
        "networkError":"Lỗi mạng, kiểm tra mạng",
        "timeoutError":"Yêu cầu đã hết thời gian, vui lòng sử dụng sau",
        "serviceError":"Server bất thường, vui lòng thử lại sau",
        "requestError":"Yêu cầu bất thường",
        "authFail":"Đăng nhập đã hết thời gian",
        "authVerifyFail":"Thông tin đăng nhập xác minh thất bại",
        "loginError":"Đăng nhập bất thường",
        "loginErrorVerify":"Đăng nhập thất bại, vui lòng vào game để kích hoạt trước",
        "tips":"Gợi ý",
        "noData":"Chưa có dữ liệu",
        "paySuccessMsg":"Thanh toán giao dịch thành công, đang chuyển hướng đến trang của nhà cung cấp",
        "payLoadingMsg":"Đang kiểm tra kết quả đơn hàng... ",
        "copySuccess":"Sao chép thành công",
        "serverEmpty":"Vui lòng chọn server",
        "roleEmpty":"Vui lòng chọn nhân vật",
        "loading":"Đang tải",
        "noMore":"Không có nhiều hơn nữa",
        "loginTips":"Vui lòng đăng nhập vào tài khoản game cần nạp",
        "recordTips":"sử nạp tiền trong 6 tháng gần đây.",
        "pleaseSelectPayMethod":"Vui lòng chọn kênh thanh toán",
        "payAmountExceedLimit":"Không có phương thức thanh toán nào: Tổng số tiền thanh toán một lần vượt quá giới hạn, vui lòng giảm số lượng mua hoặc chọn mức khác",
        "payWaiting":"Đơn hàng đang được thanh toán, vui lòng đợi.",
        "orderCancel":"Sau khi hủy đơn hàng, đơn hàng sẽ không được gửi nữa, các ưu đãi giảm giá đã hưởng sẽ được hoàn lại. Vui lòng xác nhận rằng bạn chưa thanh toán và hủy đơn hàng?",
        "paying":"Nếu đã thanh toán xong, vui lòng kiểm tra vật phẩm trong game sau.",
        "noFindRole":"Chưa tìm thấy nhân vật? Thử thay đổi tài khoản>",
        "beIntercepted":"Trình duyệt bị chặn, vui lòng bỏ chặn để xem. Chỉ hiển thị lịch",
        "maintaining":"Xin chào~Đang bảo trì kỹ thuật, dự kiến sẽ mở trước {date}, vui lòng đợi"
    },
    "account":{
        "switchAccount":"Chuyển đổi tài khoản",
        "help":"Trung tâm trợ giúp",
        "rechargeRecord":"Nhật ký giao dịch",
        "welcome":"Chào mừng đến với Trung Tâm Thanh Toán",
        "pleaseSelectRole":"Vui lòng chọn nhân vật",
        "latestLogin":"Đăng nhập gần đây",
        "changeRole":"Thay đổi nhân vật",
        "loginAccount":"Đăng nhập tài khoản",
        "selectRole":"Chọn nhân vật sever khu vực",
        "startReachrge":"Bắt đầu nạp",
        "server":"Server",
        "roleID":"ID nhân vật",
        "roleName":"Tên nhân vật",
        "country":"Quốc gia/khu vực",
        "empty":"Trống rỗng",
        "noRoles":"Tài khoản của bạn {name} hiện chưa có nhân vật nào. Vui lòng vào game tạo nhân vật hoặc đổi sang tài khoản khác để đăng nhập.",
        "roleInfo":"Thông tin nhân vật"
    },
    "mall":{
        "title":{
            "buy":"Mua hàng",
            "payCart":"Xác nhận đơn hàng",
            "payType":"Phương thức thanh toán"
        },
        "type":{
            "card":"Thẻ tín dụng/Thẻ ghi nợ",
            "bankApp":"Ngân hàng di động",
            "bankTransfer":"Chuyển khoản ngân hàng",
            "onlineBank":"Ngân hàng trực tuyến"
        },
        "otherCard":"Tài khoản khác",
        "totalPrize":"Tổng cộng",
        "date":"Thời gian ngày tháng",
        "dateRange":"Ngày tra cứu",
        "dateBegin":"Thời gian bắt đầu",
        "dateEnd":"thời gian kết thúc",
        "rangeSeparator":"đến",
        "productName":"Tên vật phẩm",
        "orderAmount":"Số tiền mua",
        "orderNumber":"Mã số đơn hàng giao dịch",
        "orderStatus":"Trạng thái đơn hàng",
        "status":{
            "unpaid":"Chưa thanh toán",
            "paySuccess":"Thanh toán thành công",
            "payFail":"Thanh toán thất bại",
            "finish":"Hoàn thành đơn hàng",
            "invalid":"Hết hiệu lực"
        },
        "cancelOrder":"Hủy đơn hàng",
        "recommend":"Gần đây sử dụng",
        "buyContinue":"Tiếp tục mua",
        "goodsTitle":"Tùy chọn nạp",
        "goodsPrizeOriginal":"Giá gốc vật phẩm",
        "goodsAdd":"Tăng/giảm tặng vật phẩm",
        "buySuccess":"Mua thành công",
        "buySuccessMsg":"Vật phẩm đã được phát, vui lòng kiểm tra vật phẩm trong game"
    },
    "pop":{
        "stepOne":"Bước 1",
        "stepOneText":"Nhấn vào công cụ trình duyệt",
        "stepTwo":"Bước 2",
        "stepTwoText":"Trong cửa sổ bật lên, chọn \"Thêm vào màn hình chính\"",
        "bookmarkPage":"Lưu trang web",
        "bookmarkPageContent":"Trên thanh điều hướng, <span class=\"key_words\">nhấn vào biểu tượng dấu trang</span> hoặc nhấn tổ hợp phím <span class=\"key_words\">Ctrl+D</span> để lưu trang web.",
        "saveDesktopAndroid":"Vui lòng nhấn vào menu \"Thêm\" của trình duyệt, chọn \"Thêm vào màn hình chính\" hoặc sử dụng chức năng dấu trang <span class='icon icon-star'></span> để lưu trang web."
    }
}