import cn from './zh-cn'
import tw from './zh-tw'
import en from './en'
import id from './id'
import th from './th'
import vi from './vi'
import ja from './ja'

export default {
    cn,
    tw,
    en,
    id,
    th,
    vi,
    ja
}