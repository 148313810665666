export default {
    "game":{
        "name":"Journey Renewed: Fate Fantasy",
        "titleGat":"Player Top-up Platform",
        "titleDny":"Journey Renewed Payment Center"
    },
    "user":{
        "helpContent":"<p>Payment Center Instruction:</p><p></p><p>1. This service is only available to users who have linked their Google, Apple, or Facebook accounts.</p><p></p><p>2. Vouchers purchased on the website will be directly sent to your game account and can be used in the game. Please check your inbox carefully.</p><p></p><p>3. Before making a payment, please double-check your account details. If the information does not match, please switch to the correct account character before proceeding with the payment.</p><p></p><p>4. If you do not receive your rewards within 30 minutes after a successful payment, please contact customer service in game.</p><br/><p>Voucher Information:</p><p>1. Vouchers are new in-game items that can be used to redeem gift packs within the game.</p><p>2. Participation in in-game activities will be based on the actual usage time of vouchers. For example: during cumulative top-up events, using vouchers to redeem gift packs in game will count towards cumulative top-ups; however, if you only purchase vouchers through the payment center without using them to redeem gift packs within the game, it will not count towards cumulative top-up events participation.</p>"
    },
    "recharge":{
        "instructions":"<p>Top-up Instructions</p><p>1. Please verify your server and character information before purchasing. Once the top-up is complete, the items will be directly delivered to your inventory. <br/>Please check your inventory carefully.</p><p>2. Website top-ups may experience delays. If the purchase fails, try logging in again or contact customer service for assistance.</p><p>3. The official website Payment Center regularly holds events. Wayfarers can keep an eye on related event announcements.</p><p></p><p>Contact Customer Service</p><p>Customer Service Email: CS-JRFF@cyou-inc.com</p>"
    },
    "footer":{
        "termsOfService":"Terms of Service",
        "privacyPolicy":"Privacy Policy",
        "officialFanGroup":"Official Fan Page",
        "serviceCenter":"Service Center",
        "copyright":"Copyright © 2024-2025 Changyou.com HK Limited. All Rights Reserved.",
        "emailTips":"If you have any feedbacks, please send to our official email ",
        "emailTipsButton":"Click to copy email address",
        "link_privacy":"https://jrff.gaming.com/privacy.html",
        "link_protocol":"https://jrff.gaming.com/protocol.html",
        "link_officialFanGroup":"https://www.facebook.com/JourneyRenewed.EN",
        "email_service":"CS-JRFF@cyou-inc.com"
    }
}