import { deleteTicket } from './utils'

const REDIRECT_URL = 'redirectUrl'
const USER_INFO = 'userInfo'
const LANG = 'lang'
const COUNTRY = 'country'
const SOURCE = 'source'
const PAY_RESULT = 'payResult'
const PAY_INFO = 'payInfo'

export const SELECTED_ROLE_STORE_KEY = 'SELECTED_ROLE_STORE_KEY'

// loginByTicket 用重定向地址
export const setRedirectUrl = () => localStorage.setItem(REDIRECT_URL, deleteTicket(location.href))
export const getRedirectUrl = () => localStorage.getItem(REDIRECT_URL)

// 国家
export const setCountry = (val) => localStorage.setItem(COUNTRY, val)
export const getCountry = () => localStorage.getItem(COUNTRY)
export const removeCountry = () => localStorage.removeItem(COUNTRY)

// 语言
export const setLang = (val) => localStorage.setItem(LANG, val)
export const getLang = () => localStorage.getItem(LANG)
export const removeLang = () => localStorage.removeItem(LANG)

// 渠道
export const setSource = (val) => sessionStorage.setItem(SOURCE, val)
export const getSource = () => sessionStorage.getItem(SOURCE)
export const removeSource = () => sessionStorage.removeItem(SOURCE)

// 用户信息
export const getUserInfo = () => {
    const info = localStorage.getItem(USER_INFO)
    return info ? JSON.parse(info) : {}
}
export const setUserInfo = (data = {}) => localStorage.setItem(USER_INFO, JSON.stringify(data))
export const removeUserInfo = () => localStorage.removeItem(USER_INFO)

// 上次选择角色
export const removeSelectedRole = ()=>localStorage.removeItem(SELECTED_ROLE_STORE_KEY)

// 支付结果
export const setPayResult = (val) => localStorage.setItem(PAY_RESULT, val)
export const getPayResult = () => localStorage.getItem(PAY_RESULT)
export const removePayResult = () => localStorage.removeItem(PAY_RESULT)

// 支付信息
export const getPayInfo = () => {
    const info = localStorage.getItem(PAY_INFO)
    return info ? JSON.parse(info) : {}
}
export const setPayInfo = (data) => localStorage.setItem(PAY_INFO, JSON.stringify(data))
export const removePayInfo = () => localStorage.removeItem(PAY_INFO)

