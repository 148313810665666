export default {
    "header":{
        "gameRecharge":"เติมเงินเกม",
        "hello":"สวัสดี",
        "title":"แพลตฟอร์มเติมเงิน",
        "saveDesktopTitle":"ศูนย์การชำระเงิน Journey Renewed"
    },
    "btn":{
        "login":"เข้าสู่ระบบ",
        "confirm":"ยืนยัน",
        "cancel":"ยกเลิก",
        "clear":"ชำระล้าง",
        "pay":"สรุปผล",
        "payNow":"ชำระทันที",
        "gotIt":"ยืนยัน",
        "saveDesktop":"บันทึกหน้าจอ",
        "saveDesktopMobile":"บันทึกหน้าจอ"
    },
    "form":{
        "label":{
            "region":"เลือกภูมิภาค",
            "lang":"เลือกภาษา"
        },
        "placeholder":{
            "select":"โปรดเลือก{name}"
        }
    },
    "warn":{
        "networkError":"เครือข่ายผิดพลาด โปรดตรวจสอบเครือข่าย",
        "timeoutError":"คำขอหมดเวลา โปรดใช้ในภายหลัง",
        "serviceError":"เซิร์ฟเวอร์ผิดปกติ กรุณาลองใหม่อีกครั้งในภายหลัง",
        "requestError":"คำขอผิดปกติ",
        "authFail":"ล็อกอินหมดเวลา",
        "authVerifyFail":"การตรวจสอบข้อมูลล็อกอินล้มเหลว",
        "loginError":"ล็อกอินผิดปกติ",
        "loginErrorVerify":"การเข้าสู่ระบบล้มเหลว โปรดเข้าเกมเพื่อเปิดใช้งาน",
        "tips":"เคล็ดลับ",
        "noData":"ไม่มีข้อมูล",
        "paySuccessMsg":"การชำระเงินสำเร็จ กำลังข้ามไปที่หน้าผู้ขาย",
        "payLoadingMsg":"กำลังสอบถามผลลัพธ์การสั่งซื้อ...",
        "copySuccess":"คัดลอกสำเร็จ",
        "serverEmpty":"กรุณาเลือกเซิร์ฟเวอร์",
        "roleEmpty":"โปรดเลือกตัวละคร",
        "loading":"กำลังโหลด...",
        "noMore":"ไม่มีอีกแล้ว",
        "loginTips":"กรุณาล็อกอินบัญชีเกมที่ต้องการเติมเงิน",
        "recordTips":"แสดงเฉพาะบันทึกการเติมเงินภายใน 6 เดือนเท่านั้น",
        "pleaseSelectPayMethod":"กรุณาเลือกช่องทางการชำระเงิน",
        "payAmountExceedLimit":"ไม่สามารถชำระเงินได้: ยอดรวมการชำระเงินในครั้งเดียวเกินกำหนด โปรดลดปริมาณการซื้อหรือเลือกช่องทางอื่น",
        "payWaiting":"กรุณารอสักครู่ กำลังชำระเงินคำสั่งซื้อ",
        "orderCancel":"จะไม่มีการส่งมอบสินค้าต่อหลังจากยกเลิกคำสั่งซื้อ จะได้รับโปรโมชั่นส่วนลดคืน คุณต้องการยืนยันที่จะยกเลิกคำสั่งซื้อรายการนี้รวมถึงไม่ดำเนินการชำระเงิน?",
        "paying":"กรุณาตรวจสอบสินค้าภายในเกมหลังชำระเงินเรียบร้อยแล้ว",
        "noFindRole":"ไม่พบตัวละคร? ลองสลับบัญชีดูสิ>",
        "beIntercepted":"ถูกเบราว์เซอร์บล็อก โปรดปลดบล็อกก่อน",
        "maintaining":"สวัสดี~ ขณะนี้กำลังอยู่ระหว่างการบำรุงรักษา คาดว่าจะเปิดก่อน {date} กรุณารอสักครู่"
    },
    "account":{
        "switchAccount":"สลับบัญชี",
        "help":"ศูนย์ช่วยเหลือ",
        "rechargeRecord":"ประวัติการทำรายการ",
        "welcome":"ยินดีต้อนรับสู่ศูนย์การชำระเงิน",
        "pleaseSelectRole":"โปรดเลือกตัวละคร",
        "latestLogin":"ล็อกอินล่าสุด",
        "changeRole":"เปลี่ยนตัวละคร",
        "loginAccount":"ล็อกอินบัญชี",
        "selectRole":"เลือกเซิร์ฟเวอร์ตัวละคร",
        "startReachrge":"เริ่มเติมเงิน",
        "server":"เซิร์ฟเวอร์",
        "roleID":"ID ตัวละคร",
        "roleName":"ชื่อตัวละคร",
        "country":"ประเทศ/ภูมิภาค",
        "empty":"ว่างเปล่า",
        "noRoles":"ขณะนี้ไม่มีตัวละครใน {name} บัญชีของท่าน โปรดเข้าสู่เกมเพื่อสร้างตัวละครก่อน หรือเข้าสู่ระบบด้วยบัญชีอื่น",
        "roleInfo":"รายละเอียดตัวละคร"
    },
    "mall":{
        "title":{
            "buy":"ซื้อสินค้า",
            "payCart":"ยืนยันออเดอร์",
            "payType":"วิธีการชำระเงิน"
        },
        "type":{
            "card":"บัตรเครดิต/บัตรเดบิต",
            "bankApp":"Bank app",
            "bankTransfer":"โอนเงินผ่านธนาคาร",
            "onlineBank":"ธนาคารออนไลน์"
        },
        "otherCard":"บัญชีอื่น",
        "totalPrize":"ยอดรวม",
        "date":"วันและเวลา",
        "dateRange":"วันที่สอบถาม",
        "dateBegin":"เวลาเริ่มต้น",
        "dateEnd":"เวลาสิ้นสุด",
        "rangeSeparator":"ถึง",
        "productName":"ชื่อผลิตภัณฑ์",
        "orderAmount":"จำนวนเงินที่ซื้อ",
        "orderNumber":"หมายเลขคำสั่งซื้อ",
        "orderStatus":"สถานะคำสั่งซื้อ",
        "status":{
            "unpaid":"ยังไม่ชำระเงิน",
            "paySuccess":"ชำระเงินสำเร็จ",
            "payFail":"การชำระเงินล้มเหลว",
            "finish":"คำสั่งซื้อเสร็จสิ้น",
            "invalid":"โมฆะ"
        },
        "cancelOrder":"ยกเลิกคำสั่งซื้อ",
        "recommend":"การใช้งานล่าสุด",
        "buyContinue":"ซื้อต่อ",
        "goodsTitle":"ตัวเลือกการชำระเงิน",
        "goodsPrizeOriginal":"ราคาเดิมผลิตภัณฑ์",
        "goodsAdd":"แถมสินค้า / ส่วนลด",
        "buySuccess":"ซื้อสำเร็จ",
        "buySuccessMsg":"ชำระเงินสินค้าเรียบร้อย กรุณาตรวจสอบสินค้าภายในเกม"
    },
    "pop":{
        "stepOne":"ขั้นตอนที่ 1:",
        "stepOneText":"คลิกที่เครื่องมือเบราว์เซอร์",
        "stepTwo":"ขั้นตอนที่ 2:",
        "stepTwoText":"คลิก \"เพิ่มลงในหน้าจอหลัก\" ในกล่องป๊อปอัป",
        "bookmarkPage":"บุ๊คมาร์คหน้าเว็บ",
        "bookmarkPageContent":"<span class=\"key_words\">คลิกไอคอนบุ๊คมาร์ค</span>หรือ <span class=\"key_words\">Ctrl+d</span> ในแถบนำทางเพื่อบุ๊กมาร์กหน้าเว็บ",
        "saveDesktopAndroid":"กรุณาคลิกที่ฟังก์ชั่นเพิ่มเติมของเบราว์เซอร์ เลือก \"เพิ่มหน้าจอ\" หรือฟังก์ชั่นบุ๊คมาร์ค <span class='icon icon-star'></span> เพื่อบันทึกหน้าเว็บ"
    }
}