// 环境
export const isLocal = process.env.VUE_APP_ENV === 'local'
export const isDev = process.env.NODE_ENV === 'development'
export const isProd = process.env.NODE_ENV === 'production'    



export const isMock = isLocal && false  // 是否mock数据

export const pcMinWitdh = 1200; // PC端最小显示宽度
export const pcMaxHeight = 500; // PC端最小显示高度

export const isMobileUA = /HarmonyOS|鸿蒙|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const isAndroid = /android/i.test(navigator.userAgent.toLowerCase())

export const isIos = /\((i|Mac)[^;]+;( U;)?.*Mac OS X/.test(window.navigator.userAgent)
export const isChrome = /Chrome/.test(window.navigator.userAgent)

export const isPwa = (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://')

