export default {
    "game":{
        "name":"Reinkarnasi Perjalanan: Fantasi Takdir",
        "titleGat":"Platform Top-Up untuk Pemain",
        "titleDny":"Pusat Top Up Reinkarnasi Perjalanan"
    },
    "user":{
        "helpContent":"<p>Deskripsi Pusat Top Up</p><p></p><p>1. Layanan ini hanya terbuka untuk pengguna yang telah menghubungkan ke akun Google, Apple, dan Facebook.</p><p></p><p>2. Voucher yang dibeli melalui situs web akan langsung dikirimkan ke akun game Anda dan dapat langsung digunakan dalam game. Silakan para pemain untuk mengeceknya kembali.</p><p></p><p>3. Silakan periksa detail akun Anda dengan cermat sebelum melakukan pembayaran. Jika informasi akun tidak sesuai, silakan ganti karakter dan beralih ke akun yang benar sebelum melanjutkan ke langkah pembayaran berikutnya.</p><p></p><p>4. Jika Anda tidak menerima hadiah dalam waktu 30 menit setelah pembayaran berhasil, silakan untuk menghubungi CS dalam game.</p><br/><p>Deskripsi Voucher:</p><p>1. Voucher adalah item baru dalam game yang dapat digunakan untuk menukarkan kado yang ada di dalam game.</p><p>2. Partisipasi pada event dalam game akan didasarkan pada waktu penggunaan Voucher yang sebenarnya. Misalnya, selama masa event akumulasi Top-Up, menggunakan Voucher untuk menukarkan kado dalam game akan dihitung sebagai bagian dari event akumulasi Top-Up; Jika hanya membeli Voucher di Pusat Top Up tanpa menggunakan Voucher untuk menukarkan kado dalam game, maka tidak akan terhitung sebagai bagian dari event akumulasi Top-Up.</p>"
    },
    "recharge":{
        "instructions":"<p>Keterangan Top Up</p><p>1. Sebelum melakukan Top Up, mohon periksa kembali info server dan karakter. Setelah Top Up selesai, item akan langsung dikirimkan ke dalam Tas. <br/>Silakan Wayfarer untuk mengeceknya.</p><p>2. Top Up melalui website ada kemungkinan mengalami keterlambatan. Jika pembelian gagal, silakan relogin atau menghubungi CS untuk bantuan.</p><p>3. Pusat Top Up website official akan secara berkala mengadakan event. Wayfarer dapat memperhatikan pengumuman event terkait.</p><p></p><p>Kontak CS</p><p>Email CS: CS-JRFF@cyou-inc.com</p>"
    },
    "footer":{
        "termsOfService":"Ketentuan Layanan",
        "privacyPolicy":"Kebijakan Privasi",
        "officialFanGroup":"Fanpage Official",
        "serviceCenter":"Pusat Layanan",
        "copyright":"Copyright © 2024-2025 Changyou.com HK Limited. All Rights Reserved.",
        "emailTips":"Bila terdapat saran atau keluhan, silakan bisa menghubungi email official kami ",
        "emailTipsButton":"Ketuk untuk menyalin alamat email",
        "link_privacy":"https://jrff.gaming.com/game/id/privacy.html",
        "link_protocol":"https://jrff.gaming.com/game/id/protocol.html",
        "link_officialFanGroup":"https://www.facebook.com/JourneyRenewed.ID",
        "email_service":"CS-JRFF@cyou-inc.com"
    }
}