export default {
    "game":{
        "name":"最游幻想",
        "titleGat":"玩家公园充值平台",
        "titleDny":"Journey Renewed 支付中心"
    },
    "user":{
        "helpContent":"<p>支付中心说明</p><p></p><p>1. 本服务仅对已绑定 Google、Apple 和 Facebook 的用户开放。</p><p></p><p>2. 网页端购买的金券将直接发放至您的游戏账号，并可以在游戏中直接使用，请各位玩家注意查收。</p><p></p><p>3. 支付前请仔细核对您的账户详情。如果账户信息不符，请重新更换角色，切换至正确的账号角色后，进行下一步付款。</p><p></p><p>4. 付款成功后，如果您在付款成功后 30 分钟内仍未收到奖品，请联系游戏内客服人员。</p><br/><p>金券说明：</p><p>1. 金券是游戏内一种新的游戏道具，使用金券可以兑换游戏内对应价位的礼包。</p><p>2. 游戏内活动参与将以金券的实际使用时间为准。例：累充活动期间，使用金券在游戏内兑换礼包，，会正常计入到累充活动中；若仅在支付中心中购买金券，并未在游戏内使用金券兑换礼包，则不会计入到累充活动中。</p>"
    },
    "recharge":{
        "instructions":"<p>【充值说明】</p><p>1.充值前请再次确认区服角色信息，充值完成后，道具将直接发放到您的行囊，请各位书灵大人注意查收</p><p>2.网页端充值可能会有延迟,如到账失败可尝试重新登录或联系客服处理</p><p>3.官方网页充值中心将不定期开展活动,玩家可关注相关活动公告</p><p></p><p>【联系客服方式】</p><p>客服邮箱：CS-JRFF@cyou-inc.com</p>"
    },
    "footer":{
        "termsOfService":"服务条款",
        "privacyPolicy":"隐私协议",
        "officialFanGroup":"官方粉丝团",
        "serviceCenter":"服务中心",
        "copyright":"Copyright © 2024-2025 Changyou.com HK Limited. All Rights Reserved.",
        "emailTips":"欢迎您将反馈意见发送到我们的官方邮箱",
        "emailTipsButton":"点击复制邮箱地址",
        "link_privacy":"https://jrff.gaming.com/game/zh-cn/privacy.html",
        "link_protocol":"https://jrff.gaming.com/game/zh-cn/protocol.html",
        "link_officialFanGroup":"https://www.facebook.com/JourneyRenewed.CHS",
        "email_service":"CS-JRFF@cyou-inc.com"
    }
}