export default {
    "game":{
        "name":"最遊幻想",
        "titleGat":"玩家公園儲值平臺",
        "titleDny":"最遊幻想支付中心"
    },
    "user":{
        "helpContent":"<p>付款中心說明</p><p></p><p>1. 本服務僅對已綁定 Google、Apple 和 Facebook 的用戶開放。</p><p></p><p>2. 網頁端購買的金券將直接發放至您的遊戲帳號，並可以在遊戲中直接使用，請各位玩家注意查收。</p><p></p><p>3. 付款前請仔細核對您的帳戶詳情。如果帳戶資訊不符，請重新更換角色，切換至正確的帳號角色後，進行下一步付款。</p><p></p><p>4. 付款成功後，如果您在付款成功後 30 分鐘內仍未收到獎品，請聯繫遊戲內客服人員。</p><br/><p>金券說明：</p><p>1. 金券是遊戲內一種新的遊戲道具，使用金券可以兌換遊戲內的禮包。</p><p>2. 遊戲內活動參與將以金券的實際使用時間為準。例：累儲活動期間，使用金券在遊戲內兌換禮包，會正常計入到累儲活動中；若僅在付款中心中購買金券，並未在遊戲內使用金券兌換禮包，則不會計入到累儲活動中。</p>"
    },
    "recharge":{
        "instructions":"<p>【儲值說明】</p><p>1.儲值前請再次確認區服角色信息，充值完成後，道具將直接發放到您的行囊，請各位書靈大人注意查收</p><p>2.網頁端儲值可能會有延遲,如到賬失敗可嘗試重新登錄或聯系客服處理</p><p>3.官方網頁儲值中心將不定期開展活動,玩家可關注相關活動公告</p><p></p><p>【聯系客服方式】</p><p>客服郵箱：CS-JRFF@cyou-inc.com</p>"
    },
    "footer":{
        "termsOfService":"服務條款",
        "privacyPolicy":"隱私協議",
        "officialFanGroup":"官方粉絲團",
        "serviceCenter":"服務中心",
        "copyright":"Copyright © 2024-2025 Changyou.com HK Limited. All Rights Reserved.",
        "emailTips":"歡迎您將回饋意見發送至我們的官方信箱",
        "emailTipsButton":"點選複製信箱地址",
        "link_privacy":"https://saiyugenso.com.tw/privacy.html",
        "link_protocol":"https://saiyugenso.com.tw/protocol.html",
        "link_officialFanGroup":"https://www.facebook.com/saiyugenso/",
        "email_service":"service@gamenuts.com.tw"
    }
}