import { isMobileUA, pcMaxHeight, pcMinWitdh } from '@/utils/constants';

export const setFavicon = (newIconUrl) => {
  var newLink = document.createElement('link');
  newLink.rel = 'icon';
  newLink.type = 'image/x-icon';
  newLink.href = newIconUrl;

  var oldLinks = document.querySelectorAll('link[rel="icon"]');
  for (var i = 0; i < oldLinks.length; i++) {
    oldLinks[i].parentNode.removeChild(oldLinks[i]);
  }

  document.head.appendChild(newLink);
}

export const deleteUrlParam = (url, param) => {
  url = url.replace(new RegExp(`[?&]${param}=[^&]*`, 'gi'), '')

  if (!/\?/.test(url)) {
    url = url.replace(/&/, '?')
  }

  return url
}

export const urlQuery = (lowerCase = true) => {
  const search = window.location.search
  const query = search.substring(1)
  const vars = query.split('&')
  const json = {}
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    const key = lowerCase ? pair[0].toLowerCase() : pair[0]
    json[key] = pair[1]
  }
  return json
}

export const getTwo = (n) => {
  n = n.toString();
  return n[1] ? n : "0" + n;
}

export const deleteTicket = (url) => {
  const targetUrl = url || window.location.href
  let urlWithoutTicket = deleteUrlParam(targetUrl, 'ticket')
  if (!url) {
    window.history.replaceState({}, document.title, urlWithoutTicket)
  }
  return urlWithoutTicket
}

export const deleteSource = (url) => {
  const targetUrl = url || window.location.href
  let urlWithoutTicket = deleteUrlParam(targetUrl, 'usersource')
  if (!url) {
    window.history.replaceState({}, document.title, urlWithoutTicket)
  }
  return urlWithoutTicket
}

export const generateRandomString = (length) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const formatTime = (second) => {
  let s = second % 60
  let m = Math.floor(second / 60)
  let h = 0
  if (m > 60) {
    h = Math.floor(m / 60)
    m = m % 60
  }
  const timeMS = getTwo(m) + ':' + getTwo(s)
  return h > 0 ? (getTwo(h) + ':' + timeMS) : timeMS
}

export const formatDateTime = (now) => {
  now = now ? new Date(now) : new Date();
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var date = now.getDate();
  var hour = now.getHours();
  var minute = now.getMinutes();
  var second = now.getSeconds();
  return year + "-" + getTwo(month) + "-" + getTwo(date) + " " + getTwo(hour) + ":" + getTwo(minute) + ":" + getTwo(second);
}

export const formatTimeZoneDateTime = (timeZone = 'Asia/Shanghai', timestamp = Date.now()) => {
  try {
    const date = new Date(timestamp).toLocaleString('zh-CN', {
      timeZone,
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    })
    return date
  }catch(e) {
    return timestamp
  }
}

export function formatCurrencyToThousand(value) {
  return value?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export const splitTimeZone = (timeZone) => {
  const arr = timeZone.split('(')
  const timeZoneName = arr[0]
  const timeZoneGMT = arr[1]?.substring(0, arr[1].length -1)
  const arrGMT = timeZoneGMT.split(':')
  return {
    timeZoneName,
    timeZoneGMT: `UTC/GMT${getTwo(arrGMT[0])}:${arrGMT[1]}`
  }
}

export const getNavLang = () => {
  const navLang = navigator.language || navigator.userLanguage
  return {
    'en': 'en-US',
    'id': 'id-ID',
    'th': 'th-TH',
    'vi': 'vi-VN',
    'ja': 'ja-JP'
  }[navLang] || navLang
}

export const formatLangsShow = (val) => {
  return {
    'english': 'English'
  }[val] || val
}

/**
 * 移动端判断
 */
export function isMobile() {
  const isPC = window.innerHeight > pcMaxHeight && window.innerWidth > pcMinWitdh;
  return isMobileUA ? true : !isPC;
}
