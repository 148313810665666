import { AMSCashierPayment } from '@alipay/ams-checkout'
import { tips } from './index'
import i18n from '@/i18n'
import { getLang } from './storage'

export const payStatusList = [
  {
    name: 'unpaid',     // 未支付
    code: 0
  },
  {
    name: 'paySuccess', // 支付成功
    code: 1
  },
  {
    name: 'payFail',    // 支付失败
    code: 11
  },
  {
    name: 'finish',     // 订单完成
    code: 2
  },
  {
    name: 'invalid',    // 失效
    code: 3
  }
]

export const getPayStatusByCode = (code) => payStatusList.find(item => item.code === code)?.name

export const getPayIconByName = name => {
  if (!name) return ''

  name = name.toLocaleLowerCase()

  const payTypeIcon = {
    mycard: 'mycard.png',
    visa: 'visa.png',
    mastercard: 'mastercard.png',
    card: 'card.png',

    bankapp_krungthaibank: 'krungthaibank.png',
    onlinebanking_krungthaibank: 'krungthaibank.png',
    banktransfer_krungthaibank: 'krungthaibank.png',
    bankapp_siamcommercialbank: 'siamcommercialbank.png',
    onlinebanking_siamcommercialbank: 'siamcommercialbank.png',
    banktransfer_siamcommercialbank: 'siamcommercialbank.png',
    onlinebanking_bangkokbank: 'bangkokbank.png',
    banktransfer_bangkokbank: 'bangkokbank.png',
    banktransfer_bankofayudhya: 'bankofayudhya.png',
    onlinebanking_bankofayudhya: 'bankofayudhya.png',
    banktransfer_governmentsavingsbank: 'governmentsavingsbank.png',
    promptpay: 'promptpay.png',
    paynow: 'paynow.png',

    paypay: 'paypay.webp',
    seveneleven: 'seveneleven.webp',
    konbini: 'konbini.webp',
    onlinebanking_payeasy: 'payeasy.webp',
    grabpay_sg: 'grabpay.webp',     // 新加坡
    grabpay_ph: 'grabpay.webp',     // 菲律宾
    grabpay_th: 'grabpay.webp',     // 泰国
    grabpay_my: 'grabpay.webp',     // 泰国
    shopeepay_sg: 'shopeepay.webp',
    shopeepay_ph: 'shopeepay.webp',
    shopeepay_id: 'shopeepay.webp', // 印尼
    shopeepay_th: 'shopeepay.webp', // 印尼
    alipay_cn: 'alipay.webp',
    tng: 'tng.webp',
    boost: 'boost.webp',
    gcash: 'gcash.webp',
    truemoney: 'truemoney.webp',
    kplus: 'kplus.webp',
    onlinebanking_kasikornbank: 'kasikornbank.webp',
    banktransfer_kasikornbank: 'kasikornbank.webp',
    dana: 'dana.webp',
    qris: 'qris.webp',
    onlinebanking_bni: 'bin.webp',
    banktransfer_bni: 'bin.webp',
    onlinebanking_bca: 'bca.webp',
    onlinebanking_bsi: 'bsi.webp',
    banktransfer_bsi: 'bsi.webp',
    cimbniaga: 'cimbniaga.webp',
    banktransfer_maybank: 'maybank.webp',
    banktransfer_permata: 'permata.webp',
    atmtransfer_id: 'atmtransfer.webp',
    bankapp_bankofayudhya: 'bankapp_bankofayudhya.webp',
    alipay_hk: 'alipay_hk.png',
    pay_folder: 'pay_folder.png'
  }
  return payTypeIcon[name] ? require(`images/pay/${payTypeIcon[name]}`) : ''
}

// 判断是否可以支付
export const isCanPayByLimitInfo = ({ prizeTotal, amountLimitStart, amountLimitEnd, needValid }) => {

  if (!needValid) {
    return true
  }

  if (!amountLimitStart && !amountLimitEnd) { // 无限制
    return true
  }

  if (amountLimitEnd) { // 有上限
    if (!amountLimitStart) {
      amountLimitStart = 0
    }
    return prizeTotal >= amountLimitStart && prizeTotal <= amountLimitEnd
  } else {
    if (amountLimitStart && prizeTotal >= amountLimitStart) { // 有下限并且总价大于等于下限时
      return true
    }
  }
  return false
}

// 阿里SDK支付唤起
export const aliGlobalPay = (sessionData, needCVV) => {
  const lang = getLang()
  const checkoutApp = new AMSCashierPayment({
    environment: process.env.VUE_APP_ALIPAY_ENV,
    locale: lang.replace('-', '_') || 'en_US',
    analytics: {
      enabled: true
    },
    onLog: ({ code, message }) => {
      console.log('onLog', code)
      console.log('onLog', JSON.parse(message))
    },
    onEventCallback: ({ code, result }) => {
      console.log('onEventCallback', code)
      console.log('onEventCallback', result)
    },
    onError: (info) => {
      console.log('onError', info)
    }
  })

  checkoutApp.createComponent({
    sessionData,
    appearance: {
      showLoading: true // 默认为true，表示展示默认loading动画
    },
    notRedirectAfterComplete: false,
    merchantAppointParam: {
      storedCard: {
        needCVV // 默认为false，表示不需要用户输入CVV校验
      }
    }
  })
}

// 打开新页支付
export const linkJumpPay = (newWindow, url) => {

  // 防止浏览器阻止窗口弹出
  if(newWindow){
    newWindow.location.href = url
  }
}

export function canOpenWindow(payType, callback) {
  if (payType === 'CARD') {
    return callback()
  }
  const newWindow = window.open('', '_blank')
  if (newWindow) {
    newWindow.document.body.innerHTML = '<h1>loading...</h1>'
    callback(newWindow)
  } else {
    callback()
    return tips(i18n.$t('sys.warn.beIntercepted'))
  }
}



