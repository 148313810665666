export default {
    "header":{
        "gameRecharge":"Top-Up",
        "hello":"Hello",
        "title":"Top-up Platform",
        "saveDesktopTitle":"Journey Renewed 支付中心"
    },
    "btn":{
        "login":"Log In",
        "confirm":"Confirm",
        "cancel":"Cancel",
        "clear":"Clear",
        "pay":"Checkout",
        "payNow":"Pay Now",
        "gotIt":"Understood",
        "saveDesktop":"Save to Desktop",
        "saveDesktopMobile":"Save to Desktop"
    },
    "form":{
        "label":{
            "region":"Select Region",
            "lang":"Select Language"
        },
        "placeholder":{
            "select":"Please select{name}"
        }
    },
    "warn":{
        "networkError":"Network error. Please check your network.",
        "timeoutError":"Request timeout. Please try again later.",
        "serviceError":"Server error. Please try again later.",
        "requestError":"Request error",
        "authFail":"Login timeout",
        "authVerifyFail":"Failed to verify login information",
        "loginError":"Login error",
        "loginErrorVerify":"Log-in failed: please activate your account in the game first.",
        "tips":"Tips",
        "noData":"No data",
        "paySuccessMsg":"Transaction successful, redirecting to merchant page.",
        "payLoadingMsg":"Order result inquiry in progress...",
        "copySuccess":"Copy successful",
        "serverEmpty":"Please select server",
        "roleEmpty":"Select Character",
        "loading":"Loading…",
        "noMore":"No more data",
        "loginTips":"Please log in to the game account for top-up",
        "recordTips":"Only top-up history from the past 6 months are displayed.",
        "pleaseSelectPayMethod":"Please select payment channel",
        "payAmountExceedLimit":"Payment Method Unavailable: The total amount of the single payment exceeds the limit. Please purchase less or choose other items.",
        "payWaiting":"Please wait while your order is being processed…",
        "orderCancel":"Once the order is canceled, the item won't be delivered and the discount will be returned. Please confirm that you have not paid and you'd like to cancel the order.",
        "paying":"Please check your item in game if payment is completed.",
        "noFindRole":"Character Not Found? Try Switching Accounts>",
        "beIntercepted":"Your browser is blocking the request. Please disable the block first.",
        "maintaining":"Oops. We're under maintenance. Server will be available before {date}. Please come back later."
    },
    "account":{
        "switchAccount":"Switch Account",
        "help":"Help Center",
        "rechargeRecord":"Purchase History",
        "welcome":"Welcome to the Payment Center",
        "pleaseSelectRole":"Select Character",
        "latestLogin":"Last Login",
        "changeRole":"Change Character",
        "loginAccount":"Account Log In",
        "selectRole":"Select Server & Character",
        "startReachrge":"Start Top-Up",
        "server":"Server",
        "roleID":"Character ID",
        "roleName":"Character Name",
        "country":"Country/Region",
        "empty":"empty",
        "noRoles":"No characters found under your account {name}. Please create one or switch accounts.",
        "roleInfo":"Character Info"
    },
    "mall":{
        "title":{
            "buy":"Purchase Items",
            "payCart":"Confirm Order",
            "payType":"Payment Methods"
        },
        "type":{
            "card":"Credit Card/Debit Card",
            "bankApp":"Bank App",
            "bankTransfer":"Bank Transfer",
            "onlineBank":"Online Banking"
        },
        "otherCard":"Other Accounts",
        "totalPrize":"Total",
        "date":"Date & Time",
        "dateRange":"Search Date",
        "dateBegin":"Start date",
        "dateEnd":"End date",
        "rangeSeparator":"To",
        "productName":"Item Name",
        "orderAmount":"Purchase Amount",
        "orderNumber":"Order No.",
        "orderStatus":"Order Status",
        "status":{
            "unpaid":"Unpaid",
            "paySuccess":"Payment Successful",
            "payFail":"Payment failed",
            "finish":"Order Complete",
            "invalid":"Invalid"
        },
        "cancelOrder":"Cancel Order",
        "recommend":"Recently used",
        "buyContinue":"Continue Purchase",
        "goodsTitle":"Top-Up Options",
        "goodsPrizeOriginal":"Item Regular Price",
        "goodsAdd":"Item Bonus/Discount",
        "buySuccess":"Purchase Successful",
        "buySuccessMsg":"Item has been issued, please check in game."
    },
    "pop":{
        "stepOne":"Step 1:",
        "stepOneText":"Click the browser tools.",
        "stepTwo":"Step 2:",
        "stepTwoText":"Select 'Add to Home Screen' in the popup.",
        "bookmarkPage":"Bookmark the Page",
        "bookmarkPageContent":"Click the <span class=\"key_words\">bookmark icon</span> in the navigation bar or press <span class=\"key_words\">Ctrl+D</span> to bookmark the page.",
        "saveDesktopAndroid":"Please click the browser's more tools icon and select 'Add to Desktop' or use the bookmark feature <span class='icon icon-star'></span> to save the page."
    }
}