export default {
    "header":{
        "gameRecharge":"ゲームチャージ",
        "hello":"こんにちは",
        "title":"チャージセンター",
        "saveDesktopTitle":""
    },
    "btn":{
        "login":"ログイン",
        "confirm":"ok",
        "cancel":"キャンセル",
        "clear":"クリア",
        "pay":"お支払い",
        "payNow":"お支払いに進む",
        "gotIt":"OK",
        "saveDesktop":"デスクトップに追加",
        "saveDesktopMobile":"ホーム画面に追加"
    },
    "form":{
        "label":{
            "region":"地域",
            "lang":"言語"
        },
        "placeholder":{
            "select":"{name}を選んでください"
        }
    },
    "warn":{
        "networkError":"接続エラー。ネットワークを確認してください。",
        "timeoutError":"接続がタイムアウトしました。しばらくして再度お試しください。",
        "serviceError":"サーバーに接続できません。しばらくして再度お試しください。",
        "requestError":"リクエストにエラーが発生しました。",
        "authFail":"ログインがタイムアウトしました。",
        "authVerifyFail":"ログイン認証に失敗しました。",
        "loginError":"ログインエラー",
        "loginErrorVerify":"登録していないアカウントです。先にゲームアカウント登録をしてください。",
        "tips":"キャンセル確認",
        "noData":"データがありません。",
        "paySuccessMsg":"お支払いが完了しました。商品画面に移動中です。",
        "payLoadingMsg":"注文状況確認中です。",
        "copySuccess":"コピー成功",
        "serverEmpty":"サーバー選択",
        "roleEmpty":"キャラクター選択",
        "loading":"読み込み中",
        "noMore":"これ以上データがありません。",
        "loginTips":"チャージを行うゲームアカウントにログインしてください。",
        "recordTips":"直近1ヶ月～6ヶ月の利用履歴を表示できます。",
        "pleaseSelectPayMethod":"決済プラットフォームを選んでください",
        "payAmountExceedLimit":"ご利用可能なお支払い方法はありません。ご利用金額が上限を超えているため、商品数量を減少させるか、他の商品を購入してください。",
        "payWaiting":"お支払い処理中です。しばらくお待ちください。",
        "orderCancel":"発注がキャンセルされた場合、商品は反映されません。該当のおまけ/割引は返還されます。お支払いをまだ行っていないことを確認の上で、発注をキャンセルします。よろしいですか？",
        "paying":"お支払いが完了した場合、しばらくしてからゲーム内で購入商品の反映を確認してください。",
        "noFindRole":"ユーザーが見つからなかった場合<br/>アカウントの切替をお試しください>",
        "beIntercepted":"ブラウザによるブロックがないかを確認してください。",
        "maintaining":"現在メンテナンス中です。メンテナンス終了までしばらくお待ちください。\n終了予定時刻：{date}"
    },
    "account":{
        "switchAccount":"アカウント切替",
        "help":"ヘルプ",
        "rechargeRecord":"利用履歴",
        "welcome":"チャージセンターへようこそ",
        "pleaseSelectRole":"キャラクターを選択してください",
        "latestLogin":"最近のログイン",
        "changeRole":"ユーザー変更",
        "loginAccount":"アカウントログイン",
        "selectRole":"サーバー＆キャラクター",
        "startReachrge":"チャージに進む",
        "server":"サーバー",
        "roleID":"ユーザーID",
        "roleName":"キャラクター名",
        "country":"国・地域",
        "empty":"該当ユーザーが存在しません",
        "noRoles":"ご利用中のアカウント（{name}）にキャラクターが見つかりません。ゲーム内でキャラクターを作成するか、別のアカウントでログインしてください。",
        "roleInfo":"キャラクター情報"
    },
    "mall":{
        "title":{
            "buy":"商品購入",
            "payCart":"注文確認",
            "payType":"お支払い方法"
        },
        "type":{
            "card":"クレジットカード／デビットカード",
            "bankApp":"銀行アプリ",
            "bankTransfer":"銀行振込",
            "onlineBank":"インターネットバンキング"
        },
        "otherCard":"カードを追加する",
        "totalPrize":"合計",
        "date":"日時",
        "dateRange":"期間指定",
        "dateBegin":"開始日時",
        "dateEnd":"終了日時",
        "rangeSeparator":"～",
        "productName":"商品名",
        "orderAmount":"チャージ金額",
        "orderNumber":"注文番号",
        "orderStatus":"取引状況",
        "status":{
            "unpaid":"未支払い",
            "paySuccess":"お支払い完了",
            "payFail":"支払い失敗",
            "finish":"取引完了",
            "invalid":"失効"
        },
        "cancelOrder":"キャンセル",
        "recommend":"最近使用した",
        "buyContinue":"商品画面へ戻る",
        "goodsTitle":"商品選択",
        "goodsPrizeOriginal":"通常価格",
        "goodsAdd":"ボーナス／割引",
        "buySuccess":"チャージ完了",
        "buySuccessMsg":"ゲーム内にて購入された商品を確認してください。"
    },
    "pop":{
        "stepOne":"1. ",
        "stepOneText":"ブラウザのツールアイコンをタップします。",
        "stepTwo":"2. ",
        "stepTwoText":"出てきたメニュー内の「ホーム画面に追加」をタップします。",
        "bookmarkPage":"ブックマークに追加",
        "bookmarkPageContent":"ナビゲーションパネルのブックマークアイコンをクリックするか、キーボードの「Ctrl」＋「D」キーを押すと、ウェブサイトをブックマークに追加できます。",
        "saveDesktopAndroid":"ブラウザのその他ツールから、「ホーム画面に追加」またはブックマーク「<span class='icon icon-star'></span>」でウェブサイトを保存できます。"
    }
}